.tos-container{
    margin-top: 2rem;
}

.tos-title{
    font-size: 1.2rem;
    font-weight: bold;
}

.tos-body{
    margin-bottom: 1rem;
}