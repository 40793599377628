.wrapper{
    padding: 2rem 9%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.wrapper_header{
    text-align: center;
}

.wrapper_header h3{
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: bold;
}

.wrapper_header p{
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 1.5rem;
}

.wrapper_imgs{
    display: flex;
    /* width: 100%; */
    gap: 10px;
    margin-bottom: 1.5rem;
}

.wrapper_imgs img{
    width: 300px;
    height: 300px;
}

.wrapper_imgs .imgs_item1{
    flex: 1;
}

.wrapper_imgs .imgs_item2{
    flex: 1.5;
}

.wrapper_imgs .imgs_item3{
    flex: 1;
}

.wrapper_txt{
    width: 80%;
    margin: auto;
}

.wrapper_txt p{
    text-align: center;
    line-height: 1.8rem;
}


@media screen and (max-width: 959px) {
    .wrapper_imgs{
        display: none;
    }
}